///include /assets/js/plugins/network-property-set.js
///include /assets/js/app/p/sbproduct.js

"use strict"
/**
 * This is the full product catalogue
 */
class Catalogue extends NetworkPropertySet {
    /**
     * The general payment types
     */
    static get abstractPaymentTypes() {
        return [
            {id: "any", text: "All Payment Types"},
            //{id: "continuousAuthority", text: "Subscription (Direct Debit)"},
        ].concat(
            this.paymentTypes.map(
                o => Object.assign(
                    {},
                    o,
                    {
                        text: o.text,
                    }
                )
            )
        )
    }
    /**
     * @type {{[code: string]: {rate: number, symbol: string}}}
     */
    static get currencies() {
        return {
            AED: {
                rate: 4.60,
                symbol: "\u062f\u002e\u0625",
            },
            AUD: {
                rate: 1.96,
                symbol: "$",
            },
            BDT: {
                rate: 109.00,
                symbol: "\u09F3",
            },
            BRL: {
                rate: 5.06,
                symbol: "R$",
            },
            CAD: {
                rate: 1.64,
                symbol: "$",
            },
            CHF: {
                rate: 1.25,
                symbol: "CHF",
            },
            CZK: {
                rate: 29.08,
                symbol: "K\u010d.",
            },
            DKK: {
                rate: 8.43,
                symbol: "Kr.",
            },
            DZD: {
                rate: 149.66,
                symbol: "\u062C\u002E\u062F",
            },
            EGP: {
                rate: 62.72,
                symbol: "\u00a3"
            },
            EUR: {
                rate: 1.12,
                symbol: "\u20ac",
            },
            GBP: {
                rate: 1,
                symbol: "\u00a3",
            },
            GHS: {
                rate: 6.78,
                symbol: "GH\u20b5",
            },
            IDR: {
                rate: 19190.57,
                symbol: "Rp"
            },
            INR: {
                rate: 86.1,
                symbol: "\u20b9",
            },
            KES: {
                rate: 128.88,
                symbol: "KSh",
            },
            LKR: {
                rate: 400.14,
                symbol: "\u20a8",
            },
            MYR: {
                rate: 5.18,
                symbol: "RM",
            },
            MUR: {
                rate: 49.08,
                symbol: "\u20A8",
            },
            MVR: {
                rate: 19.4,
                symbol: "MVR",
            },
            MXN: {
                rate: 29.63,
                symbol: "$",
            },
            NGN: {
                rate: 465.60,
                symbol: "\u20A6",
            },
            NOK: {
                rate: 11.73,
                symbol: "NKr",
            },
            NPR: {
                rate: 138,
                symbol: "\u20a8",
            },
            NZD: {
                rate: 1.89,
                symbol: "$",
            },
            PHP: {
                rate: 72.89,
                symbol: "\u20B1"
            },
            PKR: {
                rate: 201.33,
                symbol: "\u20A8",
            },
            PLN: {
                rate: 5.46,
                symbol: "zł",
            },
            RON: {
                rate: 5.27,
                symbol: "lei",
            },
            SAR: {
                rate: 4.70,
                symbol: "\ufdfc",
            },
            SEK: {
                rate: 11.56,
                symbol: "kr",
            },
            SGD: {
                rate: 1.83,
                symbol: "$",
            },
            USD: {
                rate: 1.25,
                symbol: "$",
            },
            XAF: {
                rate: 740.55,
                symbol: "CFA",
            },
            XOF: {
                rate: 740.26,
                symbol: "CFA",
            },
            ZAR: {
                rate: 17.8,
                symbol: "R",
            },
        }
    }

    static defaultProductDescription(type, lang = "en-GB") {
        let content = "";
        switch(type) {
              case 'group-package':
                  content = `<ul class="list-unstyled tombstone__features fa-ul">
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_powerful', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_user_friedly_and_apps', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_state_of_the_art', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_windows_linux', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_frindly_support', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_value_for_money', lang)}</span></li>
                  </ul>`;
                  break;
              case 'group-stack_user_package_allowance':
                  content = `<ul class="list-unstyled tombstone__features fa-ul">
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_powerful', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_user_friedly_and_apps', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_state_of_the_art', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_windows_linux', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_frindly_support', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_value_for_money', lang)}</span></li>
                  </ul>`;
                  break;
              case 'group-domain':
                  content = `<ul class="list-unstyled tombstone__features fa-ul">
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_domain_description_find', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_domain_description_100_top_level', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_domain_description_buy_tansfer', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_domain_description_privacy', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_domain_description_great_value', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_domain_description_simple_cp', lang)}</span></li>
                  </ul>`;
                  break;
              case 'group-cloud_server':
                  content = `<ul class="list-unstyled tombstone__features fa-ul pb-4">
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_cloud_server_description_l1', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_cloud_server_description_l2', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_cloud_server_description_l3', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_cloud_server_description_l4', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_cloud_server_description_l5', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_cloud_server_description_l6', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_cloud_server_description_l7', lang)}</span></li>
                  </ul>`;
                  break;
              case 'group-vps':
                  content = `<ul class="list-unstyled tombstone__features fa-ul">
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_vps_description_ram', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_vps_description_cpu', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_vps_description_storage', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_vps_description_bandwith', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_vps_description_ddos', lang)}</span></li>
                  </ul>`;
                  break;
              case 'group-wordpress':
                  content = `<ul class="list-unstyled tombstone__features fa-ul">
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_powerful', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_state_of_the_art', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_wordpress', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_frindly_support', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_package_description_value_for_money', lang)}</span></li>
                  </ul>`;
                  break;
              case 'group-tls_certificate':
                  content = `<ul class="list-unstyled tombstone__features fa-ul">
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_tls_description_secure', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_tls_description_improve_performance', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_tls_description_padlock', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_tls_description_free_ssl', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_tls_description_simple_ssl', lang)}</span></li>
                      <li><span class="fa-li"><i class="fas fa-check mr-3 text-primary"></i></span><span>${translation.getOnDemand('group_tls_description_extended_ssl', lang)}</span></li>
                  </ul>`;
                  break;
        }
        return {
            content: content
        };
    }

    static get defaultShopIntro() {
        return {
            title: translation.get('gloabal_default_shop_intro'),
            welcome: translation.sprintf(
                translation.get('gloabal_default_shop_welcome'),
                ['{{ brand }}', '{{ full_name }}']
            )
        }
    }

    static get inst() {
        if(!this._inst) {
            this._inst = new Catalogue()
        }
        return this._inst
    }
    static get intervalLabels() {
        return {
            36: translation.get("global_triennially"),
            24: translation.get("global_biennially"),
            12: translation.get("global_annually"),
            6: translation.get("global_semiannually"),
            3: translation.get("global_quarterly"),
            1: translation.get("global_monthly"),
            0: translation.get("global_one_off"),
        }
    }
    static get intervals() {
        return Object.entries(this.intervalLabels).map(
            i_l => ({
                id: i_l[0],
                text: i_l[1].replace(/^(.)/, (a, $1) => $1.toUpperCase()),
            })
        )
    }
    /**
     * Just the real payment types
     */
    static get paymentTypes() {
        return [
            {id: "card", text: translation.get("global_credit_debit_card") },
            {id: "paypal", text: "PayPal" },
            {id: "balance", text: "Balance" },
            {id: "paystack", text: "Paystack" },
            {id: "paddle", text: "Paddle" },
            {id: "transfer", text: translation.get("global_mail_in_payment_bank_transfer") },
            {id: "debit", text: translation.get("global_direct_debit") },
            {id: "razorpay", text: "Razorpay" },
        ]
    }
    /**
     * @param {?number} m
     * @param {boolean} capitalise
     * @returns {string} eg. "annually"
     */
    static intervalMonthsLabel(m, capitalise = false) {
        if(m === null) return translation.get("global_unknown")
        const label = this.intervalLabels[m]
        /** @type {string} */
        let s
        if(label) {
            s = label
        } else if(m % 12) {
            s = (m == 1) ?
                translation.get(`global_every_month`) :
                translation.sprintf(
                    translation.get(`global_every_number_months`),
                    [String(m)]
                )
        } else {
            s = (m == 12) ?
                translation.get(`global_every_year`) :
                translation.sprintf(
                    translation.get(`global_every_number_years`),
                    [String(m / 12)]
                )
        }
        return capitalise ?
            s.replace(/^(.)/, (a, $1) => $1.toUpperCase()) :
            s
    }
    /**
     * @param {string} general_payment_type
     * @returns {string} The label for a general payment type (catalogue,
     *  basket)
     */
    static paymentTypeLabel(general_payment_type) {
        const pto = this.abstractPaymentTypes.find(
            i => i.id == general_payment_type
        )
        return pto ? pto.text : general_payment_type
    }
    /**
     * @param {string} real_payment_type
     * @returns {string} The label for a real payment type (payment items,
     *  payment selection)
     */
    static realPaymentTypeLabel(real_payment_type) {
        const pto = this.paymentTypes.find(i => i.id == real_payment_type)
        return pto ? pto.text : real_payment_type
    }
    constructor() {
        super()
        /** @type {?GET.Stack.Catalogue.$ns.catalogue.config.output} */
        this.config = null
        /** @type {?{[tld: string]: ?string}} */
        this.domainPremiumType = null
        /** @type {?SBGeneralProduct[]} */
        this.products = null
        /** @type {?SBGeneralProduct[]} */
        this.discontinuedProducts = []
        /** @type {?SBGeneralProduct[]} */
        this.renewableProducts = null

        /** @type {?GET.Stack.Catalogue.$ns.catalogue.config.output["currency"]} */
        this.currency = null
        /** @type {?(typeof Catalogue)["currencies"][0]["rate"]} */
        this.currencyRate = null
        /** @type {?(typeof Catalogue)["currencies"][0]["symbol"]} */
        this.currencySymbol = null
        /** @type {?GET.Stack.Catalogue.$ns.catalogue.config.output["isOpen"]} */
        this.isOpen = null

        /** @type {?GET.Services.reseller.id.packageTypes.output} */
        this.packageTypes = null

        this.prefs = null

        /** @type {?GET.Stack.Catalogue.$ns.catalogue.config.shopIntro.output} */
        this.shopIntro = null
    }
    get networkPropertyHandlers() {
        return {
            config: async () => await $.ajax(`/a/catalogue/config`),
            currency: async () => (await this.preloadSingle("config")).currency,
            currencyRate: async () => {
                const rates = await this.preloadSingle("exchangeRates")
                const currency = await this.preloadSingle("currency")
                const basket = AnyBasket.inst;
                if( this.currency != basket.currency ){
                    return 1/rates[basket.currency];
                } else {
                    return 1;
                }
            },
            currencySymbol: async () => Catalogue.currencies[
                await this.preloadSingle("currency")
            ]?.symbol,
            domainPremiumType: () => $.ajax(`/a/domainPremiumType`),
            exchangeRates: () => $.ajax(`/a/catalogue/exchangeRates`),
            isOpen: async () => (await this.preloadSingle("config")).isOpen,
            packageTypes: () => $.ajax(`/a/reseller/*/packageTypes`),
            prefs: () => this.preloadSingle("config").then(c => c.prefs || {}),
            products: async () => {
                /** @type {GET.Stack.Catalogue.$ns.catalogue.product.output} */
                var result = await $.ajax(`/a/catalogue/product`)
                result = result.filter( i => !i.code.match(/cloud_bandwidth/) )
                result.sort(
                    (a, b) => (a.shopDisplayOrder || 0) - (b.shopDisplayOrder || 0)
                );
                /** @type {SBGeneralProduct[]} */
                const out = []
                for(const p of result) {
                    if(!p.discontinued) {
                        out.push(await SBProduct.create(p))
                    } else {
                        this.discontinuedProducts.push( await SBProduct.create(p) );
                    }
                }
                return out
            },
            renewableProducts: async () => {
                /** @type {GET.Stack.Catalogue.$ns.catalogue.product.output} */
                const result = await $.ajax(`/a/catalogue/product`)
                /** @type {SBGeneralProduct[]} */
                const out = []
                for(const p of result) {
                    out.push(await SBProduct.create(p))
                }
                return out
            },
            shopIntro: async () => (await $.ajax(`/a/catalogue/config/shopIntro`)) || Catalogue.defaultShopIntro,
            supplierIsVATRegistered: async () => (await this.preloadSingle("config")).supplierIsVATRegistered,
        }
    }

    get reducedPackageTypes() {
        return(
            this.packageTypes &&
            this.packageTypes.filter(p => p.platform != 'virtual' ).map(
                pt => ({
                    text: pt.platform,
                    id: pt.id,
                })
            )
        )
    }

    /**
     *
     * @param {string} domain_name
     * @returns {Promise<boolean>}
     */
    async domainMayBePremium(domain_name) {
        const dpt =
            await this.preloadSingle("domainPremiumType")
        for(const [tld, rpt] of Object.entries(dpt)) {
            if(
                domain_name.substring(domain_name.length - tld.length) == tld.toLowerCase() &&
                ["2", "3"].includes(rpt)
            ) {
                return true
            }
        }
        return false
    }

    /**
     * @param {number} frequency
     * @returns {string}
     */
    humaniseFrequency(frequency) {
        switch (+frequency) {
            case 0:
                return;
            case 1:
                return 'Monthly'
            case 12:
                return 'Annually'
            case 3:
                return 'Quarterly'
            case 6:
                return 'Semiannually'
            case 24:
                return 'Biennially'
            case 36:
                return 'Triennially'
            default:
                throw new Error("Unknown frequency");
        }
    }

    /**
     * Formats number n like a price
     *
     * @param {number | string | null} n
     * @param {?string} [currency] eg. "GBP"
     * @returns {string}
     */
    price(n, currency = null) {
        if(n === null) return "UNKNOWN"
        const symbol = currency ?
            Catalogue.currencies[currency].symbol :
            this.currencySymbol
        return symbol + (+n).toFixed(2)
    }

    reloadConfig() {
        this.reload([
            "config",
            "currency",
            "currencyRate",
            "currencySymbol",
            "isOpen",
            "supplierIsVATRegistered",
        ])
    }

    /**
     *
     * @param {number} price
     * @returns {number}
     */
    roundCurrency(price) {
        return Math.round(price * 100) / 100
    }
}
